.fallbackLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .loader {
        width: 120px;
    }
}
