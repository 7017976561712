:root {
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-inactive-opacity: 0.6;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 3px;
    --swiper-pagination-color: #fff;
}

.swiper-pagination-bullet-active {
    --swiper-pagination-bullet-size: 8px;
}
