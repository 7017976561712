@import 'styles/media';

.pageLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.topbar {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - var(--sidebar-width));
    height: var(--topbar-height);
    padding: 0 1.5rem;
    background-color: var(--primary);
    z-index: 98;

    @include media-breakpoint-md() {
        width: 100%;
    }
}
