@import 'variables';

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #23262f;
    --toastify-color-info: #{$info};
    --toastify-color-success: #{$success};
    --toastify-color-warning: #{$warning};
    --toastify-color-error: #{$danger};
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 400px;

    --toastify-font-family: 'Inter', sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #413b3c;
    --toastify-text-color-dark: #8a8e91;
}

.Toastify__toast {
    position: relative;
    padding: 24px 44px;
    border-radius: 2px;
    border: 2px solid #413b3c;
    overflow: visible;
}

.Toastify__toast--info {
    color: var(--info);
    border-color: var(--info);
}

.Toastify__toast--success {
    color: var(--success);
    border-color: var(--success);
}

.Toastify__toast--warning {
    color: var(--warning);
    border-color: var(--warning);
}

.Toastify__toast--error {
    color: var(--danger);
    border-color: var(--danger);
}

.Toastify__toast-container--top-right {
    top: 20px;
    right: 20px;
}

.Toastify__toast-icon {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--block-bg-clr);
    border-radius: 50%;
}

.Toastify__close-button {
    position: absolute;
    top: 4px;
    right: 10px;
}
