@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/variables';
@import '~bootstrap/scss/bootstrap.scss';

@import 'styles/progressbar';

@import 'styles/swiper-overrides.scss';

@import 'react-toastify/dist/ReactToastify.css';
@import 'styles/toastify-overrides';

body {
    font-family: 'Inter', sans-serif;
    color: var(--text-clr-1);
}

.btn {
    font-size: 0.9rem;
    padding: 0.375rem 1rem;
}

.btn-link {
    text-decoration: none;
    cursor: pointer;
}

.btn-submit {
    min-width: 150px;
    padding: 0.5rem 1.5rem;
}

.text-blue {
    color: var(--blue) !important;
}

.text-bold {
    font-weight: 600;
}

.main-content {
    padding: 1.5rem;
    padding-top: calc(70px + 1.5rem);
}

.page-title {
    display: flex;
    align-items: center;
    color: var(--text-clr-2);
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 1.8rem;
}

.page-subtitle {
    color: var(--text-clr-2);
    font-size: 0.8rem;
    margin-bottom: 1.8rem;
}

.card {
    box-shadow: 1px 0 20px rgb(0 0 0 / 5%);
    border: none;
    border-radius: 2px;

    .card-body {
        padding: 1.6rem;
    }

    .card-heading {
        margin-bottom: 0;
        font-weight: 600;
    }

    .card-meta {
        color: var(--text-clr-2);
        font-size: 0.9rem;
        margin-top: 0.2rem;
    }
}

.form-label {
    color: var(--text-clr-2);
    font-size: 0.9rem;
}

.form-group {
    margin-bottom: 1.4rem;
}

.form-control,
.form-select {
    font-size: 0.9rem;
}

.form-error {
    color: var(--danger);
    font-size: 0.75rem;
    margin-top: 0.3rem;
}

.form-text {
    color: var(--text-clr-2);
    font-size: 0.7rem;
}

.form-control__bb {
    border: none;
    border-bottom: 2px solid var(--danger);
    padding: 0.6rem 0;
    width: 100%;
    outline: none;

    &:not(:placeholder-shown) {
        border-color: var(--success);
    }
}

.form-select__bb {
    border: none;
    border-bottom: 2px solid var(--danger);
    padding: 0.6rem 0;
    width: 100%;
    outline: none;
    color: var(--text-clr-2);

    &.valid {
        color: var(--text-clr-1);
        border-color: var(--success);
    }
}

.input-postpend-color-picker {
    width: 42px;

    label {
        height: 100%;
        width: 100%;
        border: 1px solid #dee2e6;
        border-left: none;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    input[type='color'] {
        visibility: hidden;
    }
}

.nav-tabs .nav-link {
    font-size: 0.9rem;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.table-hortizontal {
    th {
        padding: 1rem 0.75rem;
        color: var(--text-clr-1);
        font-size: 0.8rem;
        font-weight: 600;
        border-bottom: 1px solid #dee2e6 !important;
        white-space: nowrap;
    }

    td {
        padding: 1.5rem 0.75rem;
        color: var(--text-clr-1);
        font-size: 0.9rem;
        white-space: nowrap;
    }
}

.app-table {
    width: 100%;
    font-size: 0.9rem;

    thead tr {
        border-bottom: 1px solid #dee2e6;
    }

    th {
        font-weight: 700;
        padding: 1rem 1rem;
    }

    tbody tr {
        border-bottom: 1px solid #dee2e6;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: rgba($blue, 0.1);
            cursor: pointer;
        }
    }

    td {
        padding: 1.2rem 1rem;
        vertical-align: middle;
    }
}

///////////
.app-table-no-hover {
    tbody tr {
        &:hover {
            background-color: initial;
            cursor: default;
        }
    }
}
/// ////

.td-img {
    height: 48px;
    aspect-ratio: 4/3;
    border-radius: 2px;
    margin-right: 1rem;
    background-color: var(--bg-clr);
}

.btn-primary,
.btn-success,
.btn-danger {
    color: #fff !important;
}

.pagination {
    margin-bottom: 0;

    .page-item {
        .page-link {
            color: var(--dark);
            cursor: pointer;
        }

        &.active {
            .page-link {
                color: #fff;
                background-color: var(--dark);
                border-color: var(--dark);
            }
        }
    }
}

.modal-view-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.modal-view-content {
    color: var(--text-clr-2);
    font-size: 0.9rem;
    margin-bottom: 2.2rem;
    padding: 0 1rem;
}

.btn-modal {
    min-width: 140px;
}

.td-delete-btn {
    background-color: transparent;
    border: none;
    color: var(--danger);
}

.react-datepicker {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 10px rgb(0 0 0 / 5%);
}

.react-datepicker__header {
    background-color: var(--bg-clr);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.react-datepicker__navigation {
    top: 8px;
}

.react-datepicker-popper {
    &[data-placement^='bottom'] {
        padding-top: 0.8rem;
    }

    .react-datepicker__triangle {
        display: none;
    }
}

.react-datepicker__day--selected {
    background-color: var(--dark);
}

.react-datepicker__day--keyboard-selected {
    color: #000;
    background-color: #fff;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}
