@import 'styles/media';

.sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: var(--sidebar-width);
    height: 100%;
    background-color: var(--dark);
    z-index: 100;
    transition: transform 0.35s ease-out;

    @include media-breakpoint-md() {
        transform: translateX(-100%);
        width: 100%;

        &.show {
            transform: translateX(0%);
        }
    }

    .sidebarBrand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.6rem 1.5rem;
        height: 70px;
        min-height: 70px;

        img {
            height: 32px;
        }
    }

    .sidebarClose {
        position: absolute;
        top: 1.2rem;
        right: 1.5rem;
        background-color: transparent;
        border: none;
        padding: 0;
        display: none;
        color: #fff;
        font-size: 1.5rem;
        line-height: 0;

        @include media-breakpoint-md() {
            display: block;
        }
    }

    .sidebarNav {
        padding: 1.5rem 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .navListHeading {
        padding: 0 1.5rem;
        color: var(--text-clr-2);
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.095em;
        text-transform: uppercase;
        margin-bottom: 0.6rem;
    }

    .navList {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
    }

    .sidebarLink {
        display: flex;
        padding: 0.45rem 1.5rem;
        text-decoration: none;
        color: #fff;
        font-size: 0.95rem;
        cursor: pointer;

        &[aria-current='page'] {
            color: var(--primary);
            font-weight: 500;
        }
    }
}
