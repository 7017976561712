$primary: #df542c;
$secondary: rgb(192, 191, 191);
$success: green;
$warning: #f6c23e;
$danger: #ff5560;
$info: #36b9cc;
$dark: #010101;
$blue: #4286f4;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1900px,
);

:root {
    --primary: #{$primary};
    --secondary: #{$secondary};
    --success: #{$success};
    --warning: #{$warning};
    --danger: #{$danger};
    --info: #{$info};
    --dark: #{$dark};
    --blue: #{$blue};

    --sidebar-width: 220px;
    --topbar-height: 70px;

    --bg-clr: #f7f7f7;
    --block-bg-clr: #fff;
    --text-clr-1: #0a1832;
    --text-clr-2: #707070;
    --muted: #c0cdd8;
    --border-clr: #e6e8ec;

    --card-color: 255 255 255; // #FFFFFF
    --card-secondary-color: 248 249 251; // #F8F9FB
    --floating-color: 255 255 255; // #FFFFFF
}

[data-theme='dark'] {
    // Dark Theme Variables
    --bg-clr: #141416;
    --block-bg-clr: #23262f;
    --text-clr-1: #8a8e91;
    --text-clr-2: #75797d;
    --muted: #4f565f;
    --border-clr: #353945;

    --card-color: 255 255 255; // #FFFFFF
    --card-secondary-color: 248 249 251; // #F8F9FB
    --floating-color: 255 255 255; // #FFFFFF
}
