.backdrop {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 997;
    background-color: rgba(0, 0, 0, 0.15);
}

.modelWrapper {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
}

.modal {
    position: relative;
    max-width: 95%;
    max-height: 90%;
    background-color: var(--block-bg-clr);
    overflow-y: auto;
    border-radius: 2px;
    z-index: 9999;
}

.modalClose {
    position: absolute;
    top: 6px;
    right: 9px;
    color: var(--muted);
    font-size: 1.4rem;
    padding: 4px;
    line-height: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 999;
}

.modalBody {
    padding: 2.5rem 1.5rem 2rem;
}
